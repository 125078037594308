const formReady = () => {
  const form = document.querySelector(".modal__form");
  const modal = document.querySelector(".modal_form");
  const modalThanks = document.querySelector(".modal_ok");
  let formReq = form.querySelectorAll("._req");


  // Проверка во время потери фокуса
  var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13

  for (const input of formReq) {
    input.addEventListener(iOS ? 'mouseout' : 'blur', checkFilled);
  }

  function checkFilled () {
    if(this.value.length > 1) {
      this.classList.remove("_error");

    }
  }



  form.addEventListener("submit", formSend);

  async function formSend(e) {
    e.preventDefault();

    let error = formValidate(form);
    let formData = new FormData(form);

    if (error === 0) {
      let response = await fetch("supermail.php", {
        method: "POST",
        body: formData
      });

      if (response.ok) {
        let result = await response.json();

        form.reset();
        modal.classList.remove("modal_open");
        modalThanks.classList.add("modal_open");

        setTimeout(function () {
          modalThanks.classList.remove("modal_open");
        }, 5000);
      } else {
        console.log("error!!!");
      }
    } else {
      console.log("Заполните поля");
    }
  }

  function formValidate(form) {
    let error = 0;
    let formReq = form.querySelectorAll("._req");

    for (let index = 0; index < formReq.length; index++) {
      const input = formReq[index];
      formRemoveError(input);

      if (input.value === "") {
        formAddError(input);
        error++;
      }
    }

    return error;
  }

  function formAddError(input) {
    input.classList.add("_error");
    console.log(input, "error");
  }

  function formRemoveError(input) {
    input.classList.remove("_error");
  }

}

export default formReady;
