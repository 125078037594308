const range = () => {

  // Для реализации нужно:
  // jquery.min.js
  // jquery-ui.min.js
  // jquery.ui.touch-punch.min.js

  const activeNum = document.querySelector(".line__active-num");
  const input = document.querySelector(".persons");
  let left = 20;
  let min = 5;
  let max = 100;

  input.value = 20;

  activeNum.style.left = (left * (100 + min)) / 100 + "%";

  $(function () {
    $('.line').slider({
      range: 'min',
      min: min,
      max: max,
      value: left,
      slide: function (event, ui) {
        let val = ui.value;

        activeNum.textContent = val;
        activeNum.style.left = (val * (100 + min)) / 100 + "%";

        input.value = val;
      }
    });
  });


  $('.ui-slider-handle').draggable();



}

export default range;
