const mainSlider = () => {

    $(".hero__owl").owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        items: 1,
        autoplay:true,
        autoplayTimeout:4000,
        autoplayHoverPause:true,
        smartSpeed:1000
    });






}

export default mainSlider;