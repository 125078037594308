const reviews = () => {

    $(".reviews__owl").owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        items: 2.5,
        autoplay:false,
        responsive: {
        // breakpoint from 0 up
        0: {
            margin: 15,
            items: 1.3
        },
        768: {
            margin: 30,
            items: 3
        }
        }
    });






}

export default reviews;