const modals = () => {

  var modal;
  var modals = document.querySelectorAll(".modal-js");
  const btns = document.querySelectorAll(".modal-open-js");

  // При клике на одну из кнопок отображаем нужное модальное окно
  btns.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();

      // Если кнопка авторизации
      modal = document.querySelector(".modal_form");
      if(modal) {
        modals.forEach(elem => {
          elem.classList.remove("modal_open");
        });
        modal.classList.add("modal_open");
      }
    })
  })

  // При клике вне любого модального окна и на кнопку закрытия - скрываем его
  modals.forEach(modal => {
    let close = modal.querySelector(".modal__close");
    let modalInner = modal.querySelector(".modal__inner");

    modal.addEventListener("click", (event) => {
      const clickedElementsTree = event.composedPath();

      if (clickedElementsTree.includes(modalInner)) {
        return;
      } else {
        modal.classList.remove("modal_open");
      }
    })

    close.addEventListener("click", (e) => {
      e.preventDefault();

      if (modal.classList.contains("modal_open")) {
        modal.classList.remove("modal_open");
      }
    })
  });


}

export default modals;
