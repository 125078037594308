const menu = () => {

    const btn = document.querySelector(".header__burger");
    const closeBtn = document.querySelector(".header__burger-fixed-close");
    const fixedMenu = document.querySelector(".header__fixed");
    const fixedInner = document.querySelector(".header__fixed-inner");

    fixedMenu.addEventListener("click", (event) => {
        const clickedElementsTree = event.composedPath();

        if (!clickedElementsTree.includes(fixedInner)) {
            fixedMenu.classList.remove("active");
        }
    })

    btn.addEventListener("click", () => {
        setTimeout(() => {
            if(!fixedMenu.classList.contains("active")) {
                fixedMenu.classList.add("active");
            }
        })
    })

    closeBtn.addEventListener("click", () => {
        if(fixedMenu.classList.contains("active")) {
            fixedMenu.classList.remove("active");
        }
    })







}

export default menu;