import air from "./modules/air";
import cards from "./modules/cards";
import checkJs from "./modules/checkJs";
import date from "./modules/date";
import { useDynamicAdapt } from './modules/dynamicAdapt'
import formReady from "./modules/formReady";
import mainSlider from "./modules/mainSlider";
import maps from "./modules/map";
import mask from "./modules/mask";
import menu from "./modules/menu";
import modals from "./modules/modals";
import range from "./modules/range";
import reviews from "./modules/reviews";
import scroll from "./modules/scroll";

document.addEventListener("DOMContentLoaded", () => {
    useDynamicAdapt();
    checkJs();
    menu();
    mainSlider();
    cards();
    reviews();
    maps();
    date();
    range();
    air();
    modals();
    mask();
    scroll();
    formReady();
})

// try {
//   date();
// } catch (error) {
//   console.log(error);
// }

// try {
//   modals();
// } catch (error) {
//   console.log(error);
// }

// try {
//   scroll();
// } catch (error) {
//   console.log(error);
// }

// try {
//   mask();
// } catch (error) {
//   console.log(error);
// }

