const maps = () => {

  ymaps.ready(init);
    function init(){
        // Создание карты.
        var myMap = new ymaps.Map("map", {
            // Координаты центра карты.
            // Порядок по умолчанию: «широта, долгота».
            // Чтобы не определять координаты центра карты вручную,
            // воспользуйтесь инструментом Определение координат.
            center: [54.964582, 73.389565],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 14,
            controls: ['zoomControl'],
        });

        var myPlacemark = new ymaps.Placemark([54.964582, 73.389565], null, {
            preset: 'islands#yellowDotIcon'
        });
        myMap.geoObjects.add(myPlacemark);
    }


}

export default maps;


